import React, { useState } from 'react';
import Marquee from 'react-fast-marquee';
import styled, { keyframes } from 'styled-components';
import Slide from './Slide';

interface Props {
  images: { src: string; alt: string }[];
  direction: 'left' | 'right';
}

const Carousel = ({ images, direction }: Props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [srcThemeImage, setSrcThemeImage] = useState('');

  function handleShowImage(src: string) {
    setSrcThemeImage(src);
    setShowPopup(true);
  }

  return (
    <Container>
      <Marquee>
        {direction === 'left' ? (
          <SlidesLeft>
            {images.map((image, index) => {
              return (
                <li key={index}>
                  <Slide key={index} image={image.src} setSelectedImage={handleShowImage} direction={direction} />
                </li>
              );
            })}
          </SlidesLeft>
        ) : (
          <SlidesRight>
            {images.map((image, index) => {
              return (
                <li key={index}>
                  <Slide key={index} image={image.src} setSelectedImage={handleShowImage} direction={direction} />
                </li>
              );
            })}
          </SlidesRight>
        )}
      </Marquee>
      {showPopup && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1000,
          }}
          onClick={() => setShowPopup(false)}
        >
          <img
            style={{
              position: 'absolute',
              top: '55%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              maxWidth: '90%',
              maxHeight: '85%',
            }}
            src={srcThemeImage}
            alt=""
          />
        </div>
      )}
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  overflow: hidden;
  margin-top: 25px;
`;

const SlidesLeft = styled.ul`
  display: flex;
  animation: slide 12s;
  transition: 1s;

  li {
    position: relative;
    list-style: none;
  }
`;

const SlidesRight = styled(SlidesLeft)`
  li {
  }
`;

export default Carousel;
