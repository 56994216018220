import Image from 'next/image';
import React, { useCallback, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Carousel from 'components/Carousel';
import Link from 'components/Link';
import YoutubeVideo from 'components/YoutubeVideo';
import { media } from 'utils/media';
import FaqSection2 from 'views/PricingPage/FaqSection2';
import Carinho from '../../public/3-imagens.png';
import Bebedeitado from '../../public/bebedeitado.png';
import ElementBackground from '../../public/elementos-pendurados-home.svg';

import Mae from '../../public/mae.png';
import PaieMae from '../../public/pai-mae.png';

declare global {
  interface Window {
    gtag?: (
      key: string,
      eventName: string,
      options?: {
        send_to: string;
      },
    ) => void;
    dataLayer?: any[];
  }
}

export default function HomePageDefault() {
  const allImages = useMemo(
    () => [
      { src: '/convites-slider-home/convite2.webp', alt: '' },
      { src: '/convites-slider-home/convite3.webp', alt: '' },
      { src: '/convites-slider-home/ev1.webp', alt: '' },
      { src: '/convites-slider-home/ev2.webp', alt: '' },
      { src: '/convites-slider-home/ev3.webp', alt: '' },
      { src: '/convites-slider-home/convite7.webp', alt: '' },
      { src: '/convites-slider-home/ev4.webp', alt: '' },
      { src: '/convites-slider-home/ev5.webp', alt: '' },
      { src: '/convites-slider-home/ev9.webp', alt: '' },
      { src: '/convites-slider-home/convite4.webp', alt: '' },

      { src: '/convites-slider-home/convite2.webp', alt: '' },
      { src: '/convites-slider-home/convite3.webp', alt: '' },
      { src: '/convites-slider-home/ev1.webp', alt: '' },
      { src: '/convites-slider-home/ev3.webp', alt: '' },
      { src: '/convites-slider-home/convite7.webp', alt: '' },
      { src: '/convites-slider-home/ev4.webp', alt: '' },
      { src: '/convites-slider-home/ev5.webp', alt: '' },
      { src: '/convites-slider-home/ev8.webp', alt: '' },
      { src: '/convites-slider-home/ev9.webp', alt: '' },
      { src: '/convites-slider-home/convite4.webp', alt: '' },

      { src: '/convites-slider-home/convite2.webp', alt: '' },
      { src: '/convites-slider-home/convite3.webp', alt: '' },
      { src: '/convites-slider-home/ev1.webp', alt: '' },
      { src: '/convites-slider-home/ev3.webp', alt: '' },
      { src: '/convites-slider-home/convite7.webp', alt: '' },
      { src: '/convites-slider-home/ev4.webp', alt: '' },
      { src: '/convites-slider-home/ev5.webp', alt: '' },
      { src: '/convites-slider-home/ev9.webp', alt: '' },
      { src: '/convites-slider-home/convite4.webp', alt: '' },
    ],
    [],
  );

  const sendConversionEvent = useCallback(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', { send_to: 'AW-11161453973/nGxLCOTv4J4YEJWLmcop' });
    }
  }, []);

  return (
    <MainContainer>
      <Container>
        <div className="column left">
          <ContainerInfo>
            <h1>Presentes para uma nova vida</h1>
            <span>
              Personalize de forma simples e gratuita um lindo site para o seu evento: Chá de bebê ou aniversário do seu filho. Depois é só
              compartilhar a sua lista de presentes com seus amigos e familiares.
            </span>

            <ButtonCreateList className="first-btn" onClick={sendConversionEvent}>
              <Link href="/registration">CRIAR MINHA LISTA DE PRESENTES!</Link>
            </ButtonCreateList>
          </ContainerInfo>
        </div>

        <div className="column right">
          <img src={ElementBackground} className="elementBackground" alt="" />
          <img src="bebeprimeiro.webp" alt="Bebe" />
        </div>
      </Container>
      <SecondBanner
      >
        <ContainerUpside>
          <div className="containerVideo" title="Vídeo sobre o nascimento do site MeuNascimento">
            <YoutubeVideo url="https://www.youtube.com/watch?v=pHCZ0bJdhv0" />
          </div>
        </ContainerUpside>
      </SecondBanner>
      <ChooseYourFavorite
        style={{
          position: 'relative',
        }}
      >
        <h1>Escolha seu modelo de site favorito</h1>
        <h2>Modelos de sites para eventos de Chá de Bebê, Chá revelação, Chá de Fraldas, Chá Rifa e Aniversário</h2>
        <br />
        <br />
        <div className="cards">
          <div className="card">
            <img src="./eventoslide1.webp" alt="Card 1" />
          </div>

          <div className="card">
            <img src="./eventoslide2.webp" alt="Card 2" />
          </div>

          <div className="card">
            <img src="./eventoslide3.webp" alt="Card 3" />
          </div>

          <div className="card">
            <img src="./eventoslide4.webp" alt="Card 4" />
          </div>

          <div className="card">
            <img src="./eventoslide5.webp" alt="Card 5" />
          </div>
        </div>

        <br />
        <h2>Mais de 30 modelos gratuitos de convites personalizáveis.</h2>
        <br />
        <img
          style={{
            position: 'absolute',
            left: '-25rem',
            top: '-30rem',
            rotate: '80deg'
          }}
          src="./lollipop.png"
          alt="pirulito"
          className="lollipop"
        />
        <Carousel images={allImages} direction={'left'} />

        <br />
        <ButtonCreateList onClick={sendConversionEvent}>
          <Link href="/login">Começar agora</Link>
        </ButtonCreateList>
      </ChooseYourFavorite>
      <ContainerHowItWorks
        style={{
          marginTop: '5rem',
        }}
      >
        <h3>
          Os presentes são comprados por seus convidados, com segurança e praticidade. <br /> E o valor de cada presente é convertido em
          dinheiro, diretamente em sua conta corrente.
        </h3>
        <h2>Como funciona?</h2>
        <div className="containerInfoHowWorks">
          <img src="./1-home.webp" alt="" />
          <div>
            <img src="./2-home.webp" alt="" />
            <img src="./3-home.webp" alt="" />
          </div>
          <img src="./4-home.webp" alt="" />
        </div>

        <ButtonCreateList className="how-it-works-create-btn" onClick={sendConversionEvent}>
          <Link href="/registration">CRIAR MINHA LISTA DE PRESENTES!</Link>
        </ButtonCreateList>
      </ContainerHowItWorks>
      <HowItWorks>
        <ContainerPartBottom>
          <Image src={Carinho} alt="" />
          <div className="containerTextInfo">
            <br />
            <br />
            <br />
            <h2>Se, desde antes do nascimento da criança, você já planeja uma reserva financeira para:</h2>

            <h3>
              <span>Saúde:</span> custos do parto, vacinas, exames, consultas.
              <br />
              <br />
              <span>Educação:</span> creche, formação parental, cultura e lazer.
              <br />
              <br />
              <span>Compra inteligente e econômica:</span> fraldas da marca e na medida certa; itens de qualidade e adequados ao seu
              enxoval.
            </h3>
          </div>
        </ContainerPartBottom>
        <ContainerMyBirth>
          <div className="containerInfoTextMyBirth">
            <h2>O Meu Nascimento foi feito para você!</h2>
            <span>É inteligente, pois facilita muito a vida de quem deseja presentear o seu bebê!</span>
            <span>Até mesmo familiares que moram distantes, poderão presenteá-los com todo o carinho.</span>
            <button onClick={sendConversionEvent}>
              <Link href="/registration">CRIAR MINHA LISTA DE PRESENTES!</Link>
            </button>
          </div>
          <ContainerInfoCart>
            <Image src={Mae} alt="Imagem da mãe" />
          </ContainerInfoCart>
        </ContainerMyBirth>
      </HowItWorks>
      <WhoWeAre>
        <div className="infoText">
          <h2>Quem somos?</h2>
          <h4>Nós não somos apenas uma lista de presentes.</h4>
          <p>
            Somos pais (médica e educador) de três meninas e temos uma trajetória de apoio em saúde e educação para cerca de 1 milhão de
            famílias.
          </p>
          <p>
            Por isso, sabemos de perto quais são as suas prioridades, desde a chegada do bebê: enxoval, parto, adequação da casa, saúde,
            educação e alimentação.
          </p>
          <p>
            O Meu Nascimento nasceu com a missão de proporcionar segurança e flexibilidade para os pais através de presentes que são
            convertidos em dinheiro.
          </p>
          <p>Permitimos a conexão entre familiares e amigos num momento tão importante, que são os primeiros anos de vida do bebê.</p>
        </div>
        <div className="infoImage">
          <Image src={Bebedeitado} alt="bebe-deitado" />
        </div>
      </WhoWeAre>
      <Transition>
        <img src="./transicao.svg" alt="" />
      </Transition>
      <MoreGifts>
        <div className="containerMoreGifts">
          <h2>Mais do que presentes, o Meu Nascimento gera:</h2>
          <Image src={PaieMae} alt="" className="berco" />
        </div>
        <div className="containerInfoMoreGifts">
          <div className="informationMoreGifts">
            <img src="./coracao-home.svg" alt="" />
            <div className="infoTextMoreGifts">
              <h3>Conexão entre pessoas,</h3>
              <span>pois fortalece os laços de apoio da tribo no entorno do bebê.</span>
            </div>
          </div>
          <div className="informationMoreGifts">
            <img src="./trevo-home.svg" alt="" />
            <div className="infoTextMoreGifts">
              <h3>Economia sustentável</h3>
              <span>
                em um mundo que precisa mudar os hábitos de consumo para diminuir os impactos ambientais na perspectiva de um futuro que
                desejamos aos nossos filhos.
              </span>
            </div>
          </div>
          <div className="informationMoreGifts">
            <img src="./terceiro-icon-home.svg" alt="" />
            <div className="infoTextMoreGifts">
              <h3>Autonomia Financeira,</h3>
              <span>
                os valores recebidos são convertidos em dinheiro para que os pais possam usar quando e onde desejarem. Até mesmo com as
                prioridades do orçamento familiar, como saúde (parto, vacinas, plano de saúde) e educação de seus filhos: creche, rede de
                apoio e cultura.
              </span>
            </div>
          </div>
        </div>
      </MoreGifts>
      <CommonQuestions>
        <h2>Perguntas Frequentes</h2>
        <FaqSection2 />
        <img src="./body-home-blue.svg" alt="" className="bodyHomeBlue" />
        <img src="./bolinhas-home.svg" alt="" className="bolinhasHomeBlue" />
      </CommonQuestions>
    </MainContainer>
  );
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-display: swap;

  img {
    width: 100%;
    height: auto;
  }
  img:nth-child(2) {
    width: 100%;
    height: auto;
  }

  .column {
    flex: 1;
    margin-top: 8rem;

    span {
      color: #787af5;
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 300;
      font-size: 18px;
      line-height: 150%;
    }

    .elementBackground {
      position: absolute;
      margin-top: -100px;
      right: 270px;
      width: 10%;

      @media (max-width: 1200px) {
        width: 30%;
      }

      @media (max-width: 1000px) {
        width: 30%;
      }

      @media only screen and (max-width: 600px) {
        display: none;
      }

      @media only screen and (max-width: 1200px) and (orientation: landscape) {
        width: 15%;
        right: 40px;
      }

      @media only screen and (max-width: 912px) {
        width: 15%;
        right: 40px;
      }
    }

    .left {
      background-color: #fff6e8;
    }

    .right {
      background-color: #fff6e8;
    }
  }

  @media (max-width: 500px) {
    background-size: contain;
    background-position: 3px -155px;
    background-size: 15%;
    background-repeat: no-repeat;
    background-image: url('/elementospendurados2.png');

    .column {
      flex: 100%;
      margin-top: 1rem;
    }

    .elementBackground {
      background: #000;
    }

    .left {
      h1 {
        margin-top: 0.1rem;
        margin-left: 7rem;
      }

      span {
        text-align: left;
        position: absolute;
        top: 300px;

        font-size: 14px;

        width: 315px;

        margin-top: 14rem;
        margin-left: 7rem;
      }

      .first-btn {
        margin-top: 350px;
        margin-left: 6rem;
        z-index: 1;
      }
    }

    .right {
      background-color: #f5f5f5;

      img {
        margin-top: -52rem;
        transform: scaleX(-1);
      }
    }
  }

  @media (max-width: 470px) {
    .left {
      .first-btn {
        margin-top: 340px;
        margin-left: 6rem;
      }
    }

    .right {
      background-color: #f5f5f5;

      img {
        margin-top: -53rem;
        transform: scaleX(-1);
      }
    }
  }

  @media (max-width: 370px) {
    .left {
      .first-btn {
        margin-top: 330px;
        margin-left: 6rem;
      }
    }

    .right {
      background-color: #f5f5f5;

      img {
        margin-top: -46rem;
        transform: scaleX(-1);
      }
    }
  }
`;

const MainContainer = styled.main`
  overflow: hidden;
  font-display: swap;
  @media (max-width: 500px) {
    max-width: 100vw;
  }
`;

const ContainerInfo = styled.div`
  display: flex;
  max-width: 80vw;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 50px 10px;

  @media (max-width: 478px) {
    height: 60%;
    justify-content: space-between;
  }

  @media only screen and (max-width: 1200px) and (orientation: landscape) {
    padding: 0 2.5rem;
  }

  h1 {
    color: #8b8ee8;
    font-family: 'P22 Mackinac Pro';
    font-style: italic;
    font-weight: 800;
    font-size: 82.6726px;
    line-height: 110%;
    max-width: 680px;
    z-index: 2;

    @media (max-width: 1150px) {
      font-size: 70px;
    }

    @media (max-width: 1100px) {
      font-size: 65px;
    }

    @media (max-width: 1040px) {
      font-size: 60px;
    }

    @media (max-width: 1000px) {
      font-size: 55px;
    }

    @media (max-width: 912px) {
      font-size: 50px;
    }

    @media (max-width: 900px) {
      font-size: 50px;
      max-width: 600px;
    }

    @media (max-width: 850px) {
      font-size: 40px;
      /* width: 320px; */
    }
    @media (max-width: 700px) {
      font-size: 35px;
      width: 270px;
    }

    @media (max-width: 600px) {
      font-size: 35px;
      width: 100%;
    }

    @media (max-width: 478px) {
      width: 250px;
    }
  }

  img {
    margin-top: -1rem;
    margin-right: 4rem;

    @media (max-width: 478px) {
      width: 1045px;
      margin-right: -4rem;
      margin-top: 1rem;
    }
  }

  span {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    max-width: 650px;
    color: #8b8ee8;

    @media (max-width: 478px) {
      text-align: center;
    }
  }
  .elementBackground {
    margin-top: -10px;
  }
`;

const ButtonCreateList = styled.button`
  width: 100%;
  max-width: 565px;
  background-color: #5cc9aa;
  padding: 15px 45px;
  border-radius: 8px;
  text-transform: uppercase;
  border: none;
  font-family: 'Nunito';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  margin-top: 20px;
  margin-right: 80px;

  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);

  a {
    color: #fff6e8 !important;
  }

  @media (max-width: 478px) {
    font-size: 14px;
    width: 359px;
    max-width: 90vw;

    margin-right: -0.5px;
  }

  @media only screen and (max-width: 1200px) and (orientation: landscape) {
    margin-right: 0;
  }
`;

const SecondBanner = styled.div`
  background-color: #fff6e8;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 800px;
  z-index: ;

  @media (max-width: 478px) {
    padding-top: 20rem;
    min-height: auto;
    min-height: 400px;
  }
`;

const ContainerUpside = styled.div`
  width: 100%;
  z-index: 999;
  background-image: url('/home-elemento-segundo-banner.svg');
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 30px 80px 30px;

  .containerVideo {
    width: 100%;
    padding: 1px;
    max-width: 1056px;
    max-height: 468px;
  }

  @media (max-width: 478px) {
    padding: 0;
    .containerVideo {
      margin-top: -20.2rem;
      z-index: 9999;
    }
  }
`;

const ContainerPartBottom = styled.div`
  background-color: #5cc9aa;

  background-image: url('/body-home.svg');
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: left;

  width: 100%;
  padding: 30px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (max-width: 1100px) {
    flex-direction: column-reverse;
  }

  @media only screen and (max-width: 1200px) and (orientation: landscape) {
    width: 95%;
    background-image: none;
  }

  @media (max-width: 864px) {
    background-image: none;
  }
  @media (max-width: 478px) {
    padding: 10px 0px;
  }

  img {
    width: 100%;
    max-width: 620px;
    max-height: 520px;

    @media (max-width: 478px) {
      align-self: center;
      margin: 0;
      padding: 0;
    }
  }

  .containerTextInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 40px;
    color: #fff;

    @media (max-width: 478px) {
      margin-left: 0px;
    }

    h2 {
      font-family: 'P22 Mackinac Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 40px;

      max-width: 680px;

      @media (max-width: 1100px) {
        max-width: 300px;
      }

      @media (max-width: 478px) {
        font-size: 30px !important;
        margin-top: 2rem;
        margin-left: 0rem;
      }

      @media only screen and (max-width: 1200px) and (orientation: landscape) {
        max-width: 92%;
      }
    }

    span {
      font-weight: 700;
    }

    h3 {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 400;
      font-size: 17px;
      max-width: 693px;
      margin-left: 10px;
      margin-top: 20px;
      text-align: left;

      span {
        margin-top: 15px;
      }

      color: #f5f5f7;

      @media (max-width: 478px) {
        font-size: 20px !important;
        color: #f5f5f5;
      }
    }
    span {
      font-size: 23px;
      text-align: left;
    }
  }
`;

const HowItWorks = styled.div`
  background-color: #5cc9aa;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const ContainerMyBirth = styled.div`
  display: flex;
  a {
    color: #fff6e8;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 478px) {
    align-items: center;
    justify-content: center;
  }

  .containerInfoTextMyBirth {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-items: flex-end;
    justify-content: center;

    @media (max-width: 600px) {
      width: 100%;
      align-items: center;
      justify-content: center;
    }

    h2,
    span {
      color: #fff;
    }

    h2 {
      font-family: 'P22 Mackinac Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      max-width: 470px;

      @media (max-width: 478px) {
        font-size: 28px !important;
        padding: 20px;
        text-align: center;
      }
    }
    span {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 150%;
      max-width: 415px;
      margin-right: 50px;
      margin-top: 10px;

      @media (max-width: 478px) {
        font-size: 18px !important;
        margin-right: 2rem;
        margin-top: 2rem;
        margin-left: 2rem;
      }
    }
    button {
      box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
      background-color: #8b8ee8;
      width: 100%;
      max-width: 415px;
      padding: 15px 48px;
      font-size: 18px;
      text-transform: uppercase;
      color: #fff;
      border: none;
      margin-top: 20px;
      border-radius: 8px;
      margin-right: 50px;

      & > a {
        font-family: Nunito;

        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
      }

      @media (max-width: 478px) {
        margin-right: 0;
        margin-bottom: 30px;
        font-size: 14px !important;
        width: 90%;
      }

      @media only screen and (max-width: 1200px) and (orientation: landscape) {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 1200px) and (orientation: landscape) {
    padding: 50px;
    width: 100%;
  }
`;

const ChooseYourFavorite = styled.div`
  display: flex;
  color: #fff6e8;
  flex-direction: column;
  background-color: #8b8ee8;

  .cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    ${media('<=desktop')} {
      margin-top: 2rem;
      justify-content: center;
    }

    .card {
      display: flex;
      flex-direction: column;
      max-width: 270px;
      flex: 1 1 150px;
      color: #ddf3f5;
      margin: 1.5rem;
      cursor: pointer;
      border-radius: 8px;
      list-style: none;
      text-align: center;

      box-shadow: 0 0.2rem 0.9rem rgba(0.9, 0.9, 0.6, 0.9);

      :hover {
        transition: 0.6s;
        transform: scale(1.11);
      }
      img {
        width: 100%;
        height: auto;
        border-radius: 5px;
      }
      ${media('<=desktop')} {
        width: 328px;
      }
    }
  }

  .card {
    width: 15%;
    margin: 5px;
    text-align: center;
  }

  .card img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  width: 100%;

  align-items: center;
  justify-content: center;
  padding: 80px 0;

  @media (max-width: 400px) {
    width: 100vw;
  }

  h1 {
    font-family: 'P22 Mackinac Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 120%;
    color: #fff6e8;
    max-width: 519px;
    text-align: center;
    max-width: 463px;

    @media (max-width: 478px) {
      font-size: 32px !important;
      padding: 20px;
    }
  }

  h2 {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 150%;

    color: #fff6e8;
    margin-top: 1rem;

    @media (max-width: 478px) {
      width: 353px;
      text-align: center;
      max-width: 96vw;
    }
  }

  @media (max-width: 478px) {
    text-align: center;
  }
`;

const ContainerInfoCart = styled.div`
  width: 50%;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const ContainerHowItWorks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 5rem;

  width: 100%;
  padding: 30px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  background-image: url('/heart.svg');
  background-size: auto;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: 4rem;
  background-position-x: -4rem;

  h3 {
    font-family: 'P22 Mackinac Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 120%;
    margin-bottom: 43px;
    margin-top: 43px;
    color: #8b8ee8;

    @media (max-width: 478px) {
      font-size: 16px !important;
      text-align: center;
      margin-top: -2rem;
    }
  }

  h2 {
    font-family: 'P22 Mackinac Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 58px;
    line-height: 120%;
    margin-bottom: 43px;
    margin-top: 43px;
    color: #8b8ee8;

    @media (max-width: 478px) {
      font-size: 36px !important;
      text-align: center;
      margin-top: -2rem;
    }
  }

  .lollipop {
    position: absolute;
    z-index: 9999;
    top: -40%;
    left: 80%;

    @media (max-width: 1700px) {
      right: -10px;
    }
    @media (max-width: 1600px) {
      right: -15px;
    }
    @media (max-width: 1400px) {
      right: -10px;
      width: 20%;
    }
    @media (max-width: 1000px) {
      right: -10px;
      width: 18%;
    }
    @media (max-width: 900px) {
      right: -12px;
      width: 16%;
    }
    @media (max-width: 860px) {
      display: none;
    }
  }

  .containerInfoHowWorks {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 95%;
    flex-wrap: wrap;

    img {
      margin: 5px 20px 20px 11px;

      @media (max-width: 478px) {
        margin: 5px 0px 30px 0px;
      }
    }
  }

  @media (max-width: 478px) {
    background-image: none;

    .how-it-works-create-btn {
      display: none;
    }
  }

  @media only screen and (max-width: 1370px) {
    background-image: none;
  }

  @media only screen and (max-width: 1200px) and (orientation: landscape) {
    background-image: none;

    .lollipop {
      display: none;
    }

    img {
      width: 100vw;
    }
  }
`;

const WhoWeAre = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff6e8;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
  }

  div {
    width: 50%;
    display: flex;
    flex-direction: column;
  }

  .infoText {
    align-items: flex-end;
    h2 {
      font-family: 'P22 Mackinac Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 120%;
      color: #8b8ee8;
      width: 100%;
      max-width: 463px;

      @media (max-width: 478px) {
        font-size: 36px !important;
        padding: 20px;
        text-align: center;
      }
    }
    h4 {
      font-family: 'P22 Mackinac Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 120%;
      color: #8b8ee8;
      margin: 10px 0;
      width: 100%;
      max-width: 463px;
      @media (max-width: 478px) {
        font-size: 20px !important;
      }
    }
    p {
      font-family: 'Nunito';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      margin: 10px 0;
      color: #8b8ee8;
      width: 100%;
      max-width: 463px;

      @media (max-width: 478px) {
        font-size: 18px !important;
      }
    }

    @media only screen and (max-width: 1200px) and (orientation: landscape) {
      h2,
      h4,
      p {
        max-width: 100vw;
      }
    }

    @media (max-width: 900px) {
      width: 60%;
    }
  }
  .infoImage {
    img {
      width: 100vw;
      height: auto;
    }

    @media (max-width: 900px) {
      width: 100%;
      align-items: center;
    }
  }
  .transition {
    width: 100% !important;
  }

  @media only screen and (max-width: 1200px) and (orientation: landscape) {
    flex-direction: column;
  }
`;

const Transition = styled.div`
  width: 100%;

  img {
    width: 100%;
    height: 80%;
  }
`;

const MoreGifts = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #fff6e8;
  align-items: flex-start;
  justify-content: center;
  margin-top: 100px;

  div {
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 478px) {
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
      margin-left: 0px;
    }

    .berco {
      display: none;

    }
  }

  .containerMoreGifts {
    align-items: flex-end;

    img {
      width: 100%;
      height: 100%;
    }
    
    h2 {
      align-self: center;
      font-family: 'P22 Mackinac Pro';
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 110%;
      width: 100%;
      max-width: 550px;
      color: #8b8ee8;
      align-self: flex-start;

      @media (max-width: 478px) {
        font-size: 32px !important;
        max-width: 1000px !important;
        width: 100% !important;
        margin-bottom: 20px;
        padding: 20px;
        text-align: center;
      }
    }

    @media (max-width: 478px) {
      display: flex;
      align-items: center;
      justify-content: center;


      h2 {
        margin-top: -6rem;
        margin-left: 0px;
        margin-bottom: -2rem;
      }
    }
    @media (max-width: 400px) {
      h2 {
        margin-left: 0px;
      }
    }
  }

  .containerInfoMoreGifts {
    margin-top: 7rem;

    .informationMoreGifts {
      display: flex;
      flex-direction: row;
      padding: 2rem;
      margin-bottom: 30px;
    }
    img {
      width: 80px;
      height: 80px;
      box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.5);
      border-radius: 39px;
    }
    .infoTextMoreGifts {
      margin-left: 20px;
      h3 {
        font-family: 'P22 Mackinac Pro';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        width: 100%;
        max-width: 400px;
        color: #8b8ee8;
      }
      span {
        font-family: 'Nunito';
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        width: 100%;
        max-width: 400px;
        color: #8b8ee8;
      }
    }
    }
  }
`;

const CommonQuestions = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f3aaab;
  align-items: center;
  justify-content: center;
  padding: 40px 10px;
  position: relative;

  h2 {
    font-family: 'P22 Mackinac Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    text-align: center;
    color: #ffffff;

    @media (max-width: 478px) {
      padding: 20px;
      text-align: center;
    }
  }

  .bodyHomeBlue {
    position: absolute;
    bottom: -5%;
    right: 5%;

    @media (max-width: 1400px) {
      right: 2%;
      width: 20%;
    }
    @media (max-width: 1100px) {
      right: 1%;
      width: 15%;
    }

    @media (max-width: 800px) {
      display: none;
    }
  }
  .bolinhasHomeBlue {
    position: absolute;
    top: -15%;
    left: 2%;

    @media (max-width: 1600px) {
      left: 2%;
      width: 20%;
    }

    @media (max-width: 800px) {
      display: none;
    }
  }
`;